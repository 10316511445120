<template>
    <!-- 订单列表页面 -->
    <div class="padding24 color_white" id="order_list">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div v-show="$route.meta.isShow">
                <a-form layout="inline">
                    <a-form-item label="手机号">
                        <a-input v-model="phone" placeholder="请输入手机号" @pressEnter="searchClick"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button icon="search" type="primary" @click="searchClick">搜索</a-button>
                    </a-form-item>
                </a-form>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="tableLoading"></a-table>
                <!-- 分页功能 -->
                <!-- <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>-->
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100" />
            </div>
        </a-spin>
        <router-view />
    </div>
</template>
<script>
import {SsxyTransferSearch} from "@/request/api/orderformManage";
import MyPagination from "@/components/pagination/MyPagination";
export default {
    created() {
        this.GetOrdersFn();
    },
    components: {
        MyPagination,
    },
    mounted() {

    },
    data() {
        return {
            phone:'',
            spinning: false, // 是否为全局加载中的状态
            tip: "",
            tableLoading: true, // 表格是否是加载中
            count: 0, // 列表数据的总条数
            pageNo: 1,
            pageSize: 20,

            // 表格数据
            columns: [
                {
                    title: "openId",
                    dataIndex: "openId",
                    key: "openId",
                    width: "15%",
                },
                {
                    title: "退款金额",
                    dataIndex: "amount",
                    key: "amount",
                    width: "15%",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    key: "phone",
                    width: "15%",
                },
                {
                    title: "订单号",
                    dataIndex: "orderNo",
                    key: "orderNo",
                    width: "15%",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: "15%",
                },
                {
                    title: "退款时间",
                    dataIndex: "receiveTime",
                    key: "receiveTime",
                    width: "15%",
                }
            ],
            tableData: [],
        };
    },

    methods: {
        GetOrdersFn(){
            this.tableData = []
            SsxyTransferSearch({
                phone:this.phone,
            }).then((res) => {
                if (res.code == 200) {
                    this.tableLoading = false
                    if(res.data){
                        this.tableData.push(res.data)
                    }
                }
            });
        },

        // 回车和搜索的按钮
        searchClick() {
            this.tableLoading = true;
            this.GetOrdersFn();
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.GetOrdersFn();
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#order_list");
        },
    },
};
</script>

<style lang="less" scoped>
#order_list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}
.top {
    margin-top: 15px;
    .top_line {
        margin-bottom: 20px;
        display: flex;
        span {
            margin-right: 20px;
            /deep/.ant-input {
                width: 300px;
            }
            /deep/.ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }
            /deep/.ant-calendar-picker-input {
                margin-left: 10px;
            }
        }
        .ant-btn {
            margin-right: 20px;
        }
    }
}
.marign_left20 {
    margin-left: 20px;
}
/deep/.ant-upload-picture-card-wrapper {
    width: auto;
}
/deep/ .ant-table-wrapper {
    margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
    font-weight: 600;
}
/deep/.ant-table-fixed-right {
    z-index: 99;
}
/deep/.ant-table-fixed-header .ant-table-body-inner {
    background-color: #fff;
    z-index: 99;
}
</style>
